// actionTypes.js
export const CREATE_ESCROW_REQUEST = 'CREATE_ESCROW_REQUEST';
export const CREATE_ESCROW_SUCCESS = 'CREATE_ESCROW_SUCCESS';
export const CREATE_ESCROW_FAILURE = 'CREATE_ESCROW_FAILURE';

export const ESCROW_STATUS_UPDATE = 'ESCROW_STATUS_UPDATE';
export const ESCROW_RECEIVED = 'ESCROW_RECEIVED';
export const ESCROW_STATUS_UPDATE_REQUEST = 'ESCROW_STATUS_UPDATE_REQUEST';
export const UPDATE_ESCROW_FAILURE = 'UPDATE_ESCROW_FAILURE';
export const ACCEPT_TERMS_REQUEST = 'ACCEPT_TERMS_REQUEST';
export const ACCEPTED_TERMS = 'ACCEPTED_TERMS';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const CLEARED_MESSAGE = 'CLEARED_MESSAGE';
export const ESCROW_DISPUTE_REQUEST = "ESCROW_DISPUTE_REQUEST";
export const ESCROW_DISPUTE_SUCCESS = "ESCROW_DISPUTE_SUCCESS";
export const ESCROW_DISPUTE_FAILURE = "ESCROW_DISPUTE_FAILURE";
export const ACCEPT_CANCEL = 'ACCEPT_CANCEL';
export const ACCEPTED_CANCEL = 'ACCEPTED_CANCEL';