import { isMobile, isTablet, isDesktop } from "react-device-detect";

export const detectDeviceType = () => {
    if (isMobile) {
        return "mobile";
    } else if (isTablet) {
        return "tablet";
    } else if (isDesktop) {
        return "desktop";
    }
};

export const toggleContentVisibility = (deviceType, action) => {
    const leftsidebar = document.querySelector("#leftbar");
    const verticleMenu = document.querySelector("#verticleMenu");
    const pageContent = document.querySelector(".page-content");
    const pageContentInside = document.querySelector(".page-content .right-msg.container");
    const content = document.querySelector("#content");

    if (!leftsidebar || !content) return;

    if (deviceType === "mobile") {
        if (action === "hide") {
            leftsidebar.style.display = "block";
            verticleMenu.style.display = "block";
            pageContent.style.paddingLeft = "0px";
            content.style.display = "none";
            pageContentInside.style.width = "100%";
            pageContentInside.style.marginLeft = "0px";
        } else if (action === "show") {
            leftsidebar.style.display = "none";
            verticleMenu.style.display = "none";
            pageContent.style.paddingLeft = "0px";
            content.style.display = "block";
            pageContentInside.style.width = "100%";
            pageContentInside.style.marginLeft = "0px";
        }
    } else if (deviceType === "tablet") {
        leftsidebar.style.display = "block";
        verticleMenu.style.display = "block";
        pageContent.style.paddingLeft = "20px";
        pageContentInside.style.width = "91%";
        pageContentInside.style.marginLeft = "70px";
        content.style.display = "block";
    } else if (deviceType != "desktop") {
        // Desktop
        leftsidebar.style.display = "block";
        verticleMenu.style.display = "block";
        pageContent.style.paddingLeft = "14px";
        pageContentInside.style.width = "91%";
        pageContentInside.style.marginLeft = "70px";
        content.style.display = "block";
    }
};
